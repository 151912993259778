<template>
  <el-card shadow="hover">
    <div slot="header">
      <span>点位图设计方案表</span>
    </div>
    <!-- <div id="container"></div> -->
    <div id="container" hidden></div>
    <el-row :gutter="20">
      <el-col :span="6"
        ><el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入点位图方案相关信息进行筛选"
          clearable
      /></el-col>
      <el-button type="primary" @click="getSolution">查询方案</el-button>
      <el-button type="primary" style="float: right" @click="pushAddPage"
        >新增方案</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        v-loading="loading2"
        stripe
        style="width: 100%"
        border
        @expand-change="expandChange"
      >
        <!-- 方案里的楼层信息 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand_box">
              <el-table
                ref="systemSolutionDetailsTableRef"
                :data="scope.row.floors"
                v-loading="loading"
                border
                :summary-method="getSystemDetialSummaries"
                :show-summary="true"
              >
                <!-- 楼层里的房间信息 -->
                <el-table-column type="expand">
                  <template slot-scope="cscope">
                    <div class="expand_box">
                      <el-table :data="cscope.row.rooms" border>
                        <!-- 房间里的产品信息 -->
                        <el-table-column type="expand">
                          <template slot-scope="pscope">
                            <div class="expand_box">
                              <el-table :data="pscope.row.products" border>
                                <el-table-column
                                  type="index"
                                  width="80"
                                  label="#"
                                ></el-table-column>
                                <el-table-column
                                  prop="productName"
                                  show-overflow-tooltip
                                  label="商品名称"
                                >
                                </el-table-column>
                                <el-table-column label="商品品牌">
                                  <template slot-scope="pscope">
                                    {{ pscope.row.productBrandName }}
                                  </template>
                                </el-table-column>

                                <el-table-column prop="number" label="数量">
                                </el-table-column>
                                <el-table-column
                                  prop="productPrice"
                                  label="价格"
                                >
                                </el-table-column>
                                <el-table-column prop="allPrice" label="总价格">
                                </el-table-column>
                                <el-table-column label="型号规格">
                                  <template slot-scope="pscope">
                                    {{
                                      pscope.row.productModel +
                                      '/' +
                                      pscope.row.productSpec
                                    }}
                                  </template>
                                </el-table-column>
                                <el-table-column prop="remark" label="备注">
                                </el-table-column>
                              </el-table>
                            </div>
                          </template>
                        </el-table-column>

                        <el-table-column
                          type="index"
                          width="80"
                          label="#"
                        ></el-table-column>
                        <el-table-column
                          prop="name"
                          show-overflow-tooltip
                          label="房间名称"
                        >
                        </el-table-column>
                        <el-table-column label="产品个数">
                          <template slot-scope="cscope">
                            {{ cscope.row.productNumber }}
                          </template>
                        </el-table-column>

                        <el-table-column prop="totalPrice" label="总价格">
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  type="index"
                  width="80"
                  label="#"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  show-overflow-tooltip
                  label="楼层名称"
                >
                </el-table-column>
                <el-table-column label="房间个数">
                  <template slot-scope="scope">
                    {{ scope.row.roomLength }}
                  </template>
                </el-table-column>
                <el-table-column prop="allProductNum" label="产品个数">
                </el-table-column>
                <el-table-column prop="totalPrice" label="总报价">
                </el-table-column>
                <el-table-column prop="remark" label="备注"> </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="name" label="点位图设计方案名称">
        </el-table-column>
        <el-table-column prop="createTime" label="制表时间"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column width="380" label="编辑">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="primary"
              @click="openDownLoadPdfDialog(scope.row.id)"
              >下载方案<i class="el-icon-arrow-down el-icon--right"></i></el-button
            > -->
            <el-dropdown
              @command="handleCommand(scope, $event)"
              style="margin-right: 10px"
            >
              <el-button size="mini" type="primary"
                >下载方案<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="PDF">PDF</el-dropdown-item>
                <el-dropdown-item command="Excel">Excel</el-dropdown-item>
                <el-dropdown-item command="PPT">PPT</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button
              size="mini"
              @click="pushEdit(scope.row.id)"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deleteNowSln(scope.row.id)"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>
    <el-dialog
      title="点位图设计方案Pdf下载"
      :visible.sync="downLoadPdfDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPdfDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrder"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrder"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="点位图设计方案Excel下载"
      :visible.sync="downLoadExcelDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadExcelDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrderExcel"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrderExcel"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="点位图设计方案PPT下载"
      :visible.sync="downLoadPPTDialogVisible"
      width="36%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>是否确认下载方案[{{ nowSelectDragOrder.name }}]?</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="downLoadPPTDialogVisible = false"
          >取消</el-button
        >
        <!-- <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        > -->
        <el-button
          type="primary"
          @click="downLoadDragOrderPPT"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import clip from '@/utils/clipboard'
import {
  GetdragOrderList,
  getDragOrderById,
  dragOrderDelete,
  DownLoadPDF,
  DownLoadExcel,
  DownLoadPPT,
} from '@/api/DragOrder/index'
import { getUserList } from '@/api/user.js'
import { getProductById } from '../../../api/product'

import { Graph } from '@antv/x6'
import { Dnd } from '@antv/x6-plugin-dnd'
import { Transform } from '@antv/x6-plugin-transform'
import { Export } from '@antv/x6-plugin-export'

import JSZip from 'jszip'
import saveAs from 'file-saver'

export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // 表格数据
      tableData: [],
      //分享
      showShareDialogVisible: false,
      //用户列表
      userListData: [],
      // 用户筛选条件
      userQueryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // //当前方案Id
      // nowSolutionId: 0,
      loading: false,
      loading2: false,
      // fullscreenLoading: false,

      //查看方案图纸
      orderData: {},
      floorList: [],
      graph: null,
      dnd: null,
      parentNode: null,
      childNode: [],
      jsonData: null,
      nowSelectFloor: {},
      nowSelectDragOrder: {},

      // 确认下载弹出框是否可见
      //Pdf
      downLoadPdfDialogVisible: false,
      //Excel
      downLoadExcelDialogVisible: false,
      //PPT
      downLoadPPTDialogVisible: false,
      ImgBaseList: [],
      renderDonePromise: null,
    }
  },
  created() {
    this.GetdragOrder()
  },
  methods: {
    async init() {
      if (this.graph) {
        this.graph.dispose()
        this.graph = null
      }
      this.graph = new Graph({
        container: document.getElementById('container'),
        autoResize: true,
        panning: true,
        mousewheel: true,
        background: {
          color: '#F2F7FA',
        },
        grid: {
          visible: true,
          type: 'doubleMesh',
          args: [
            {
              color: '#eee', // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: '#ddd', // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 4, // 主次网格线间隔
            },
          ],
        },
        translating: {
          restrict(view) {
            if (view) {
              const cell = view.cell
              if (cell.isNode()) {
                const parent = cell.getParent()
                if (parent) {
                  return parent.getBBox()
                }
              }
            }
          },
        },
      })

      const resizingOptions = {
        enabled: true,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      const rotatingOptions = {
        enabled: false,
        orthogonal: false,
        restrict: false,
        preserveAspectRatio: false,
      }

      this.dnd = new Dnd({
        target: this.graph,

        validateNode: (node) => {
          if (!this.parentNode) {
            return false
          }
          let { x: px, y: py } = this.parentNode.store.data.position
          let { width: pw, height: ph } = this.parentNode.store.data.size

          let { x: nx, y: ny } = node.store.data.position
          let { width: nw, height: nh } = node.store.data.size
          let isOk = !(
            nx < px ||
            nx + nw > px + pw ||
            ny < py ||
            ny + nh > py + ph
          )

          if (isOk) {
            this.childNode.push(node)
            this.parentNode.addChild(node)
            let data = node.data
            data.label = node.data.productName
            data.nodeId = node.id
            this.jsonData.cells.forEach((item) => {
              if (item.id == node.id) {
                data.position = item.position
              }
            })
            this.floorList.forEach((item) => {
              if (item.name == this.nowSelectFloor.name) {
                item.jsonData = this.nowSelectFloor.jsonData
                item.rooms.forEach((element) => {
                  if (element.label == this.nowSelectRoom.label) {
                    element.children.push(data)
                    let productData = {
                      cellId: JSON.stringify(data.position),
                      productId: data.id,
                      productImage: data.thumbImage,
                      productModel: data.modelName,
                      productSpec: data.productSpec,
                      productPrice: data.productPrice,
                    }
                    element.products.push(productData)
                  }
                })
                this.getRoomList()
              }
            })
          }

          return isOk
        },
        getDragNode: (node) => node.clone({ keepId: true }),
        getDropNode: (node) => node.clone({ keepId: true }),
      })

      this.graph
      .use(
        new Transform({
          resizing: resizingOptions,
          rotating: rotatingOptions,
        })
      )
      // 导出插件
      .use(new Export())

      var img = new Image()
      img.src = this.nowSelectFloor.fileUrl

      await new Promise((resovle, reject) => {
        img.onload = () => {
          resovle()
        }
      })

      if (this.jsonData != {} && this.jsonData.cells != undefined) {
        this.graph.clearCells()
        this.graph.off('render:done'); // 移除旧的监听器
        this.graph.fromJSON(this.jsonData)
        this.renderDonePromise = new Promise((resolve, reject) => {
          this.graph.on('render:done', () => {
            // 当事件触发时，调用 resolve
            console.log('渲染完成');
            resolve(true);
          });
        });

        this.parentNode = this.graph.getNodes()[0]
        // console.log('1212parentNode', this.graph.toJSON())
        let childCells = []
        let cellList = this.graph.toJSON()
        cellList.cells.forEach((cell) => {
          if (cell.zIndex != 1) {
            childCells.push(cell)
          }
        })
        this.childNode = childCells
      }
      // 获取所有节点
      const nodes = this.graph.getNodes()

      // 遍历所有节点，移除工具
      nodes.forEach((node) => {
        node.removeTools() // 移除所有工具
      })

      this.graph.on('cell:mouseenter', ({ cell }) => {
        if (cell.isNode()) {
          if (cell.id == this.parentNode.id) {
            return
          }

          cell.addTools([
            {
              name: 'boundary',
              args: {
                attrs: {
                  fill: '#7c68fc',
                  stroke: '#333',
                  'stroke-width': 1,
                  'fill-opacity': 0.2,
                },
              },
            },
            {
              name: 'button-remove',
              args: {
                x: 0,
                y: 0,
              },
            },
          ])
        }
      })
      this.graph.on('node:move', ({ cell }) => {
        if (cell.zIndex != 1) {
          this.oldPosition = cell.store.data.position
          // console.log(this.oldPosition)
        } else {
          this.oldPositionList = []
          this.childNode.forEach((cell) => {
            let data = { id: cell.id, position: cell.store.data.position }
            this.oldPositionList.push(data)
          })
        }
      })

      this.graph.on('node:moved', ({ cell }) => {
        console.log('节点移动')
        console.log(cell)
        if (cell.zIndex != 1) {
          this.floorList.forEach((floor) => {
            if (this.nowSelectFloor.name !== floor.name) {
              return
            }
            floor.rooms.forEach((room) => {
              room.children.forEach((item) => {
                if (
                  JSON.stringify(item.position) !==
                  JSON.stringify(this.oldPosition)
                ) {
                  return
                }
                item.position = cell.store.data.position
                room.products.forEach((product) => {
                  if (product.cellId === item.cellId) {
                    product.cellId = JSON.stringify(item.position)
                    item.cellId = JSON.stringify(item.position)
                  }
                })
              })
            })
          })
        } else {
          console.log(this.oldPositionList)
          console.log(this.floorList)
          this.childNode.forEach((item) => {
            this.oldPositionList.forEach((data) => {
              if (item.id === data.id) {
                // console.log(19,item)
                data.newPosition = item.store.data.position
              }
            })
          })
          this.floorList.forEach((floor) => {
            if (this.nowSelectFloor.name !== floor.name) {
              return
            }
            floor.rooms.forEach((room) => {
              room.children.forEach((child) => {
                this.oldPositionList.forEach((data) => {
                  if (child.cellId === JSON.stringify(data.position)) {
                    let product = room.products.find(
                      (product) => product.cellId === child.cellId
                    )
                    product.cellId = JSON.stringify(data.newPosition)
                    child.cellId = JSON.stringify(data.newPosition)
                    child.position = data.newPosition
                  }
                })
              })
            })
          })
        }
        console.log(this.floorList)
      })

      this.graph.on('cell:mouseleave', ({ cell }) => {
        cell.removeTools()
      })

      this.graph.on('cell:removed', ({ cell }) => {
        // 删除 this.childNode 中的对应 cell
        if (cell.zIndex != 1) {
          this.floorList.forEach((item) => {
            if (item.name == this.nowSelectFloor.name) {
              item.rooms.forEach((element) => {
                element.children.forEach((item) => {
                  if (
                    JSON.stringify(item.position) ===
                    JSON.stringify(cell.store.data.position)
                  ) {
                    let product = element.products.find(
                      (u) => u.cellId === item.cellId
                    )
                    product.isDelete = true
                  }
                })
                element.children = element.children.filter(
                  (u) => u.cellId !== JSON.stringify(cell.store.data.position)
                )
              })
              item.jsonData = this.nowSelectFloor.jsonData
            }
          })

          if (this.childNode) {
            this.childNode = this.childNode.filter((child) => child !== cell)
          }
          // 删除 this.parentNode 中的对应 cell
          if (this.parentNode && Array.isArray(this.parentNode.children)) {
            this.parentNode.setChildren(
              this.parentNode.children.filter((child) => child.id !== cell.id)
            )
          }
        }
        console.log(this.floorList)
        this.getRoomList()
      })
    },
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    //获取点位图设计方案列表
    async GetdragOrder() {
      let { data } = await GetdragOrderList(this.queryFilter)
      console.log(data)
      this.tableData = data.data
      this.queryFilter.total = data.total
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getSolution()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getSolution()
    },

    //设计方案条件查询
    getSolution() {
      this.GetdragOrder()
    },
    //选择下载PDF或者Excel
    handleCommand(scope, $event) {
      // console.log(scope)
      // console.log($event)
      if ($event == 'PDF') {
        this.openDownLoadPdfDialog(scope.row.id)
      }
      if ($event == 'Excel') {
        console.log($event)
        this.openDownLoadExcelDialog(scope.row.id)
      }
      if ($event == 'PPT') {
        console.log($event)
        this.openDownLoadPPTDialog(scope.row.id)
      }
    },
    // 前往新增页面
    pushAddPage() {
      // this.$router.push({ path: '/Solution/SlnDesign' })
      this.$router.push({ path: '/drageOrder' })
    },
    // 获取拼接之后的数据
    async expandChange(row, expandedRows) {
      this.loading = true
      let { data } = await getDragOrderById(row.id)

      const promises = this.tableData.map(async (item, index, arr) => {
        if (item.id == row.id) {

          const promises = data.floors.map(async (floor) => {

            floor.roomLength = floor.rooms.length

            let allProductNum = 0

            const promises = floor.rooms.map(async (room) => {

              let arrayList = []

              let productNumber = 0

              const promises = room.products.map(async (product) => {
                try {
                  let id = product.productId

                  let { data } = await getProductById(id)

                  product.productName = data.productName
                  product.productBrandName = data.productBrandName

                  let arrayData = {}

                  arrayData = { ...product }

                  if (arrayList.length > 0) {

                    if (
                      arrayList.some(
                        (item) =>
                          item.productId === arrayData.productId &&
                          arrayData.productModel === item.productModel &&
                          item.productSpec === arrayData.productSpec
                      )
                    ) {
                      let adata = arrayList.find(
                        (item) =>
                          item.productId === arrayData.productId &&
                          arrayData.productModel === item.productModel &&
                          item.productSpec === arrayData.productSpec
                      )
                      adata.number += arrayData.productNumber
                      productNumber += arrayData.productNumber
                      adata.allPrice = adata.number * adata.productPrice
                    } else {
                      arrayData.number = arrayData.productNumber
                      productNumber += arrayData.productNumber

                      arrayData.allPrice =
                        arrayData.number * arrayData.productPrice
                      arrayList.push(arrayData)
                    }
                  } else {
                    arrayData.number = arrayData.productNumber

                    productNumber = arrayData.productNumber

                    arrayData.allPrice =
                      arrayData.number * arrayData.productPrice
                    arrayList.push(arrayData)
                  }
                } catch (error) {
                  console.error('Error fetching product:', error)
                  this.$message.error(error)
                  // 可以选择如何处理错误，例如记录日志或者抛出异常
                }
              })

              await Promise.all(promises)

              room.products = arrayList
              let allPrice = 0

              arrayList.forEach((item) => {
                allPrice += item.allPrice
              })

              room.productNumber = productNumber

              allProductNum += productNumber

              room.totalPrice = allPrice
            })
            await Promise.all(promises)

            let allPrice = 0

            floor.rooms.forEach((room) => {
              allPrice += room.totalPrice
            })

            floor.allProductNum = allProductNum
            floor.totalPrice = allPrice
          })
          await Promise.all(promises)

          arr[index].floors = data.floors
        }
      })
      await Promise.all(promises)

      console.log('this.tableData', this.tableData)

      this.loading = false
    },
    // 获取系统详情统计
    getSystemDetialSummaries(param) {
      console.log(param)
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '统计'
          return
        }

        if (index === 4 || index === 5) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const values = data.map((item) => {
              console.log(item)
              console.log(column)
              console.log(column.property)
              console.log(item[column.property])
              return Number(item[column.property])
            })

            if (!values.every((value) => isNaN(value))) {
              let val = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)

              sums[index] = index == 4 ? val : val.toFixed(2)
              sums[index] += index == 4 ? '件' : '元'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['systemSolutionDetailsTableRef'].doLayout()
      })

      return sums
    },
    //打开确认下载弹出框
    async openDownLoadPdfDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadPdfDialogVisible = true
    },
    async openDownLoadExcelDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadExcelDialogVisible = true
    },
    async openDownLoadPPTDialog(id) {
      let { data } = await getDragOrderById(id)
      // console.log(data);
      this.nowSelectDragOrder = data
      this.downLoadPPTDialogVisible = true
    },
    //生成ImgBaseList
    async getImgBaseList() {
      this.ImgBaseList = []
      try {
        if (this.nowSelectDragOrder.name !== '') {
          for (let floor of this.nowSelectDragOrder.floors) {
            this.nowSelectFloor = floor
            this.jsonData = JSON.parse(this.nowSelectFloor.jsonData)
            await this.init() // 确保 init 方法是异步的
            await this.renderDonePromise;
            // await new Promise((resolve) => setTimeout(resolve, 1000))
            // this.graph.exportSVG('测试');
            // this.graph.toSVG((dataUri) => {
            //   // 创建一个新的 Image 元素来展示 PNG 图片
            //   // const img = new Image();
            //   // img.src = dataUri;
            //   let fileName =
            //     this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            //   console.log(dataUri)

            //   let data = {
            //     fileName: fileName,
            //     fileUrl: dataUri,
            //   }
            //   this.ImgBaseList.push(data)
            //   // document.body.appendChild(img);
            // })
            // this.graph.exportPNG('myDiagram.png');

            // await this.graph.toPNG((dataUri) => {
            //   let fileName =
            //     this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            //   console.log(dataUri)

            //   let data = {
            //     fileName: fileName,
            //     fileUrl: dataUri,
            //   }
            //   this.ImgBaseList.push(data)
            // });

            const dataUri = await new Promise((resolve) => {
              this.graph.toPNG((dataUri) => {
                resolve(dataUri);
              });
            });
            let fileName =
              this.nowSelectDragOrder.name + '_' + this.nowSelectFloor.name
            console.log(dataUri)

            let data = {
              fileName: fileName,
              fileUrl: dataUri,
            }
            this.ImgBaseList.push(data)
            // 转换为 PNG
            // this.graph.toPNG((dataUri) => {
            //   // 处理数据 URI，例如创建一个下载链接
            //   const link = document.createElement('a');
            //   link.href = dataUri;
            //   link.download = 'myDiagram.png';
            //   console.log(link);
            //   link.click();
            // }, {
            //   background: '#ffffff', // 可选背景色
            //   padding: 10, // 可选边距
            // });
          }
        }
      } catch (error) {
        console.error('Error in openDownLoadPdfDialog:', error)
        // 可以在这里处理错误或者抛出异常
      }
    },
    //下载当前设计方案 Pdf
    async downLoadDragOrder() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      // let ImgBaseList = [];
      // try {
      //   if (this.nowSelectDragOrder.name !== "") {
      //     for (let floor of this.nowSelectDragOrder.floors) {
      //       this.nowSelectFloor = floor;
      //       this.jsonData = JSON.parse(this.nowSelectFloor.jsonData);
      //       await this.init(); // 确保 init 方法是异步的
      //       await new Promise(resolve => setTimeout(resolve, 1000));
      //       // this.graph.exportSVG('测试');
      //       this.graph.toSVG((dataUri) => {
      //         // 创建一个新的 Image 元素来展示 PNG 图片
      //         // const img = new Image();
      //         // img.src = dataUri;
      //         let fileName = this.nowSelectDragOrder.name+ "_" + this.nowSelectFloor.name;
      //         console.log(dataUri)

      //         let data = {
      //           fileName: fileName,
      //           fileUrl: dataUri
      //         }
      //         ImgBaseList.push(data)
      //         // document.body.appendChild(img);
      //       });
      //       // this.graph.toPNG((dataUri) => {
      //       //   // 创建一个新的 Image 元素来展示 PNG 图片
      //       //   const img = new Image();
      //       //   img.src = dataUri;
      //       //   console.log(img.src)
      //       //   document.body.appendChild(img);
      //       // });
      //     }
      //   }
      // } catch (error) {
      //     console.error("Error in openDownLoadPdfDialog:", error);
      //     // 可以在这里处理错误或者抛出异常
      // }
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      // setTimeout(() => {
      //   this.fullscreenLoading = false;
      //   this.downLoadPdfDialogVisible = false;
      // }, 1000);

      // this.fullscreenLoading = false;

      await DownLoadPDF(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          // 创建一个隐藏的<a>标签
          console.log(res)
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadPdfDialogVisible = false
    },
    //下载当前设计方案 Excel
    async downLoadDragOrderExcel() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadExcel(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
          // this.downLoadExcelDialogVisible = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadExcelDialogVisible = false
    },
    // 下载当前设计方案 PPT
    async downLoadDragOrderPPT() {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      await this.getImgBaseList()
      console.log('模拟下载成功！')

      await DownLoadPPT(this.nowSelectDragOrder.id, this.ImgBaseList)
        .then((res) => {
          console.log(res)
          // 创建一个隐藏的<a>标签
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = res.data.filePath
          link.download = res.data.fileName // 下载时文件名
          console.log(link)
          document.body.appendChild(link)

          // 模拟点击下载
          link.click()

          // 移除<a>标签
          document.body.removeChild(link)

          // this.fullscreenLoading = false
          // this.downLoadPPTDialogVisible = false
        })
        .catch((error) => {
          // 处理错误
          this.$message.success('下载失败')
          console.error('下载失败:', error)
        })
      // this.fullscreenLoading = false
      loading.close();
      this.downLoadPPTDialogVisible = false
    },
    // 下载当前方案的图纸
    async downLoadAntV(id) {
      // this.fullscreenLoading = true
      const loading = this.$loading({
        lock: true,
        text: '文件正在生成中，请耐心等待~',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      });
      let { data } = await getDragOrderById(id)
      this.nowSelectDragOrder = data

      try {
        if (this.nowSelectDragOrder.name !== '') {
          const zip = new JSZip()
          for (let floor of this.nowSelectDragOrder.floors) {
            this.nowSelectFloor = floor
            this.jsonData = JSON.parse(this.nowSelectFloor.jsonData)
            await this.init() // 确保 init 方法是异步的
            await new Promise((resolve) => setTimeout(resolve, 1000))
            let fileName =
              this.nowSelectDragOrder.name +
              '-' +
              this.nowSelectFloor.name +
              '.svg'
            // this.graph.exportSVG(fileName);
            this.graph.toSVG((dataUri) => {
              zip.file(fileName, dataUri)
            })
          }
          let zipName = this.nowSelectDragOrder.name + '.zip'
          zip
            .generateAsync({ type: 'blob' })
            .then((blob) => {
              saveAs(blob, zipName)
            })
            .catch((err) => {
              console.log('Failed to generate ZIP file:', err)
            })
        }
      } catch (error) {
        console.error('Error in downLoadAntV:', error)
        // 可以在这里处理错误或者抛出异常
      }
      console.log('下载成功！')

      setTimeout(() => {
        // this.fullscreenLoading = false
        loading.close();
      }, 1000)
    },

    //删除当前设计方案
    deleteNowSln(id) {
      this.$confirm('当前操作将会删除当前解决方案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.loading2 = true
          await dragOrderDelete(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.GetdragOrder()
          this.loading2 = false
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    // 前往编辑页面
    pushEdit(id) {
      // this.$router.push({ path: '/Solution/dragOrderEdit/' + id })
      this.$router.push({path: '/dragOrderEdit/' + id })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/dragorder.scss';
.expand_box {
  padding: 10px 20px;
  box-sizing: border-box;
}
#container {
  width: 100%;
  height: 100%;
}
</style>
